<template>
    <div>
        <viewport-layout>
            <!-- 查询区域 -->
            <template #search>
                <div class="search-wrap">
                    <span class="key">品牌</span>
                    <select v-model="brand" class="value">
                        <option :value="item.code" v-for="(item, index) in brandList" :key="index">
                            {{ item.name }}
                        </option>
                    </select>

                    <span class="key">车系</span>
                    <select v-model="series" :disabled="!brand" class="value">
                        <option :value="item.code" v-for="item in seriesList" :key="item.code">
                            {{ item.name }}
                        </option>
                    </select>

                    <Button class="btn" type="primary" @click="doSearch">查询</Button>
                </div>
            </template>

            <!-- 列表区域 -->
            <template #grid>
                <div class="grid-wrap">
                    <div class="view-list-wrap">
                        <div class="view-item" v-for="item in list" :key="item.id" @click="handleLink(item)">
                            <div class="image-wrap">
                                <img :src="item.path" v-defaultImg="nopic" />
                            </div>
                            <div class="title-wrap text-ellipsis" :title="item.seriesName">{{ item.seriesName }}</div>
                        </div>
                    </div>
                    <NoRecord v-if="list.length === 0" />
                </div>
            </template>
        </viewport-layout>

        <!-- loading -->
        <Spin size="large" fix v-if="showLoading"></Spin>
    </div>
</template>

<script>
import { request } from '@/common';
import { crud } from '@/mixins/crud';
import NoRecord from '@/pages/common/noRecord.vue';
import nopic from '@/assets/images/nopic.png';

export default {
    mixins: [crud],
    components: {
        NoRecord
    },

    data() {
        return {
            vin: '',
            list: [],
            showLoading: false,
            filterParams: {
                entranceId: '',
                brandCode: '',
                seriesCode: ''
            },
            linkUrl: '',
            nopic: nopic,
            pagingData: {
                page: 1,
                size: 100,
                total: 0
            },
            brandList: [],
            brand: '',
            seriesList: [],
            series: ''
        };
    },

    props: {},

    mounted() {
        this.filterParams.entranceId = this.$route.query.entranceId;

        const params = this.filterParams;

        this.loadData(params);
        this.loadBrand();
    },

    created() {},
    watch: {
        brand(val) {
            this.loadSeries(val);
        }
    },

    methods: {
        doSearch() {
            this.filterParams.brandCode = this.brand;
            this.filterParams.seriesCode = this.series;

            let params = this.filterParams;

            this.loadData(params);
        },

        handleLink(item) {
            const { entranceId, seriesCode } = item;
            this.$eventBus.$emit('changeModalName', item.seriesName);

            this.$router.push({
                path: '/serviceManual/cmanual',
                query: {
                    entranceId: entranceId,
                    seriesCode: seriesCode,
                    manualTypeCode: this.$route.query.manualTypeCode
                }
            });
        },

        loadData(filterParams) {
            const { page, size } = this.pagingData;
            const params = this.getPageQueryParams(filterParams, page, size);
            this.showLoading = true;

            request
                .get('/manual/entrance/series/list', params)
                .then(data => {
                    if (data.success) {
                        this.pagingData.total = data.result.total;
                        this.list = data.result.data || [];
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },

        loadBrand() {
            request
                .get('/manual/combo/brand/list')
                .then(data => {
                    if (data.success) {
                        this.brandList = data.result || [];
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {});
        },

        loadSeries() {
            const url = '/manual/combo/series/list?brandCode=' + this.brand;
            this.showLoading = true;

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        this.seriesList = data.result || [];
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        }
    }
};
</script>
<style lang="less" scoped>
@media only screen and (max-width: 1450px) {
    .view-item {
        margin-left: 0px !important;

        .image-wrap {
            width: 150px !important;
            height: 150px !important;
        }

        .title-wrap {
            width: 160px;
            padding: 5px;
            font-size: 16px !important;
            color: #000000;
        }
    }
}

/deep/ .viewport {
    display: flex;
    height: 100%;
    flex-direction: column;
    min-height: 0px;
}

/deep/ .grid {
    height: 100% !important;
    flex: 1;
    overflow-y: auto;
}

.search-wrap {
    padding: 12px 40px 0;
    font-size: 16px;
    font-weight: 600;

    .key {
        margin: 0 10px;
    }

    .value {
        border-radius: 3px;
        margin: 0 10px;
        width: 300px;
        height: 30px;
    }

    .btn {
        margin: 0 2px 2px 20px;
    }
}

.grid-wrap {
    padding: 16px;
    border-top: 1px @border-color solid;

    .view-list-wrap {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;

        .view-item {
            margin-left: 25px;

            .image-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: @border-radius-size;
                padding: 5px;
                cursor: pointer;
                padding: 5px;
                width: 230px;
                height: 230px;

                img {
                    vertical-align: middle;
                }
            }

            .title-wrap {
                width: 230px;
                padding: 5px;
                font-size: 18px;
                color: #000000;
            }

            &:hover {
                .image-wrap {
                    border: 1px solid #616df1;
                }

                .title-wrap {
                    color: #616df1;
                }
            }
        }
    }
}
</style>
